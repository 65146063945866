import http from '../../../utils/services/http'

export const updateSolicitacaoRemocao = (id, data, method = 'PUT') => {
    let url = `/api/remocoes/solicitacoes/${id}`
    let m = http.put
    if (method === 'PATCH') {
        m = http.patch
    }
    return m(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
